import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
/* import { useTheme } from '@mui/material/styles'; */
import {
  IconButton, Tooltip, Avatar, ListItemAvatar, ListItemText, ListItemButton, Menu, MenuItem, Typography,
} from '@mui/material';

import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import MovementIcon from '@mui/icons-material/FastForward';
import BlockedIcon from '@mui/icons-material/Lock';
import NoBlockedIcon from '@mui/icons-material/LockOpen';
import ErrorIcon from '@mui/icons-material/Error';
/* import MoreVertIcon from '@mui/icons-material/MoreVert'; */
import moment from 'moment';
/* import useMediaQuery from '@mui/material/useMediaQuery'; */
import StarIcon from '@mui/icons-material/Star';
import TimelineIcon from '@mui/icons-material/Timeline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
/* import Badge from '@mui/material/Badge'; */
import { devicesActions } from '../store';
import {
  formatAlarm, formatBoolean, formatPercentage, formatStatus, getStatusColorIcon,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';
/* import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg'; */
import { useAttributePreference } from '../common/util/preferences';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  /* const theme = useTheme(); */

  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');

  const secondaryText = () => {
    let status;
    /* let title; */
    if (item.status === 'online' || !item.lastUpdate) {
      /* title = formatStatus(item.status, t); */
      status = moment(item.lastUpdate).fromNow();
    } else {
      /* title = formatStatus(item.status, t); */
      status = moment(item.lastUpdate).fromNow();
    }
    return (
      <>
        {deviceSecondary && item[deviceSecondary] && `${item[deviceSecondary]} • `}
        <span className={classes[getStatusColorIcon(item.status)]}>
          {/* {title}
          {' '}
          */}
          {status}
        </span>
      </>
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  /* const desktop = useMediaQuery(theme.breakpoints.up('md')); */

  /*
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 13,
    height: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    background: getStatusColorIcon(item),
  }));
  */

  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.selectId(item.id))}
        disabled={!admin && item.disabled}
        sx={(theme) => ({
          selected: {
            backgroundColor: theme.palette.action.selected,
          },
        })}
      >
        <ListItemAvatar>
          <Tooltip title={formatStatus(item.status, t)}>
            { /*
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              badgeContent={
                <SmallAvatar src=" " alt=" " />
              }
            >
            */ }
            <Avatar style={{ background: getStatusColorIcon(item) }}>
              <img className={classes.icon} src={mapIcons[mapIconKey(item.category)]} alt="" />
            </Avatar>
            {/* </Badge> */}
          </Tooltip>
        </ListItemAvatar>
        <ListItemText
          primary={item[devicePrimary]}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {position && (
          <>
            {position.attributes.hasOwnProperty('alarm') && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="small">
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small">
                  {position.attributes.ignition ? (
                    <KeyIcon width={10} height={10} className={classes.positive} />
                  ) : (
                    <KeyOffIcon width={10} height={10} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('motion') && (
              <Tooltip title={`${t('positionMotion')}: ${formatBoolean(position.attributes.motion, t)}`}>
                <IconButton size="small">
                  {position.attributes.motion ? (
                    <MovementIcon width={10} height={10} className={classes.positive} />
                  ) : (
                    <MovementIcon width={10} height={10} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('blocked') && (
              <Tooltip title={`${t('positionBlocked')}: ${formatBoolean(position.attributes.blocked, t)}`}>
                <IconButton size="small">
                  {position.attributes.blocked ? (
                    <BlockedIcon width={10} height={10} className={classes.positive} />
                  ) : (
                    <NoBlockedIcon width={10} height={10} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('batteryLevel') && (
              <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                <IconButton size="small">
                  {position.attributes.batteryLevel > 70 ? (
                    position.attributes.charge
                      ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                      : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
                  ) : position.attributes.batteryLevel > 30 ? (
                    position.attributes.charge
                      ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                      : (<Battery60Icon fontSize="small" className={classes.medium} />)
                  ) : (
                    position.attributes.charge
                      ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                      : (<Battery20Icon fontSize="small" className={classes.negative} />)
                  )}
                </IconButton>
              </Tooltip>
            )}
            { /*
            {desktop && (
            <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreVertIcon width={10} height={10} className={classes.neutral} />
            </IconButton>
            )}
            */ }
          </>
        )}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate('/reports/trip')}>
            <PlayCircleFilledIcon>
              <PlayCircleFilledIcon />
            </PlayCircleFilledIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportTrips')}</Typography>
          </MenuItem>
          <MenuItem icon="<PlayCircleFilledIcon />" onClick={() => navigate('/reports/combined')}>
            <StarIcon>
              <StarIcon />
            </StarIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportCombined')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/route')}>
            <TimelineIcon>
              <TimelineIcon />
            </TimelineIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportRoute')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/event')}>
            <NotificationsActiveIcon>
              <NotificationsActiveIcon />
            </NotificationsActiveIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportEvents')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/stop')}>
            <PauseCircleFilledIcon>
              <PauseCircleFilledIcon />
            </PauseCircleFilledIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportStops')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/summary')}>
            <FormatListBulletedIcon>
              <FormatListBulletedIcon />
            </FormatListBulletedIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportSummary')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/chart')}>
            <TrendingUpIcon>
              <TrendingUpIcon />
            </TrendingUpIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportChart')}</Typography>
          </MenuItem>
        </Menu>
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;
