import React from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/SmartDisplay';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useMediaQuery, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import LogoImage from './LogoImage';
import Image from '../resources/images/fondo-gps.jpg';
import useFeatures from '../common/util/useFeatures';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    paddingBottom: theme.spacing(5),
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0.25, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  form: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const features = useFeatures();

  return (
    <main className={classes.root}>
      <div className={classes.sidebar}>
        {!useMediaQuery(theme.breakpoints.down('lg')) }
      </div>
      <Paper className={classes.paper}>
        {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />}
        <form className={classes.form}>
          {children}
        </form>
        {(features.disableApp && phone) ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={5}
            direction="row"
          >
            <Button style={{ width: '170px', height: '50px' }} startIcon={<AppleIcon />} size="md" href="https://apps.apple.com/ec/app/syztan-gps-solutions/id1578074456" variant="outlined">
              App Store
            </Button>
            <Button style={{ width: '170px', height: '50px' }} startIcon={<AndroidIcon />} size="md" href="https://play.google.com/store/apps/details?id=syztan.gps.solutions.V2&hl=es_CL&gl=US" variant="outlined">
              Gloogle Play
            </Button>
          </Stack>
        ) : null }
      </Paper>
    </main>
  );
};

export default LoginLayout;
