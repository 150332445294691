import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import { MDBTable, MDBTableBody } from 'mdbreact';
/* import Stack from '@mui/material/Stack'; */
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
  Accordion,
  /* AccordionSummary, */
  AccordionDetails,
  Card,
  CardContent,
  Container,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  CardMedia,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
/* import PublishIcon from '@mui/icons-material/Publish'; */
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingIcon from '@mui/icons-material/Pending';
import DescriptionIcon from '@mui/icons-material/Description';
/* import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; */
import StarIcon from '@mui/icons-material/Star';
import TimelineIcon from '@mui/icons-material/Timeline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { useTranslation } from './LocalizationProvider';
import RemoveDialog from './RemoveDialog';
import PositionValue from './PositionValue';
import { useDeviceReadonly, useRestriction } from '../util/permissions';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { devicesActions } from '../../store';
import { useCatch, useCatchCallback } from '../../reactHelper';
import { useAttributePreference } from '../util/preferences';
import SelectField from './SelectField';
import BaseCommandView from '../../settings/components/BaseCommandView';
/* import PositionPage from '../../other/PositionPage'; */

/* import { StreetView } from '../../map/StreetView';
import useFeatures from '../util/useFeatures'; */

const useStyles = makeStyles((theme) => ({
  card: {
    width: theme.dimensions.popupWidthNotPhone,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: '0.0px solid',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  actions: {
    justifyContent: 'space-between',
  },
  root: ({ desktopPadding }) => ({
    position: 'fixed',
    zIndex: 5,
    left: '50%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  }),
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  /* const features = useFeatures(); */

  /* const readonly = useRestriction('readonly'); */
  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const positionAttributes = usePositionAttributes(t);
  const positionItemsColumnOne = useAttributePreference('positionItemsColumnOne', 'ignition,motion,speed,power,course,address,odometer,latitude,longitude');
  const positionItemsColumnTwo = useAttributePreference('positionItemsColumnTwo', 'blocked,charge,batteryLevel,alarm,protocol,sat,rssi,deviceTime,serverTime,fixTime');
  const positionItemsColumnThree = useAttributePreference('positionItemsColumnThree', 'name,latitude,longitude,speed,course,altitude,accuracy,valid,protocol,address,deviceTime,fixTime,serverTime,raw,index,hdop,vdop,pdop,sat,satVisible,rssi,gps,roaming,event');

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElReport, setAnchorElReport] = useState(null);

  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const showToastMessage = () => {
    toast.success(t('commandSent'), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleGeofence = useCatchCallback(async () => {
    const RandomNumber = Math.floor(Math.random() * 100);
    const Title = t('sharedGeofence');
    const newItem = {
      name: `${device.name} - ${Title} ${RandomNumber}`,
      area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
  }, [navigate, position]);

  /* For direct send command */
  const [savedId, setSavedId] = useState(0);
  const [item, setItem] = useState({});
  const validate = () => savedId || (item && item.type);
  const limitCommands = useRestriction('limitCommands');

  const handleSend = useCatch(async () => {
    let command;
    if (savedId) {
      const response = await fetch(`/api/commands/${savedId}`);
      if (response.ok) {
        command = await response.json();
      } else {
        throw Error(await response.text());
      }
    } else {
      command = item;
    }

    command.deviceId = parseInt(deviceId, 10);

    const response = await fetch('/api/commands/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(command),
    });

    if (response.ok) {
      /* navigate('/reports/event');
      throw Error(await t('commandSent'));
      */
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <div className={classes.root}>
      {device && (
        <Draggable
          handle={`.${classes.media}, .${classes.header}`}
        >
          <Card elevation={3} className={classes.card}>
            <div className={classes.header}>
              <Typography variant="body2" color="textSecondary">
                {t('sharedDevice')}
                :
                {device.name}
              </Typography>
              <IconButton
                size="small"
                onClick={onClose}
                onTouchStart={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            {position && (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs>
                    <TabList>
                      <Tab><Typography variant="body2" color="textSecondary">{t('commandData')}</Typography></Tab>
                      <Tab><Typography variant="body2" color="textSecondary">{t('sharedDevice')}</Typography></Tab>
                      <Tab><Typography variant="body2" color="textSecondary">{t('sharedShowDetails')}</Typography></Tab>
                      {(deviceImage) ? (
                        <Tab><Typography variant="body2" color="textSecondary">{t('positionImage')}</Typography></Tab>
                      ) : null}
                      <Tab><Typography variant="body2" color="textSecondary">{t('deviceCommand')}</Typography></Tab>
                    </TabList>
                    <TabPanel>
                      {/* commandData */}
                      <CardContent className={classes.content}>
                        <Table size="small">
                          <MDBTable scrollY>
                            <MDBTableBody>
                              {positionItemsColumnOne.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                                <StatusRow
                                  key={key}
                                  name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                                  content={(
                                    <PositionValue
                                      position={position}
                                      property={position.hasOwnProperty(key) ? key : null}
                                      attribute={position.hasOwnProperty(key) ? null : key}
                                    />
                                  )}
                                />
                              ))}
                            </MDBTableBody>
                          </MDBTable>
                        </Table>
                      </CardContent>
                    </TabPanel>
                    <TabPanel>
                      {/* sharedDevice */}
                      <CardContent className={classes.content}>
                        <Table size="small">
                          <MDBTable scrollY>
                            <MDBTableBody>
                              {positionItemsColumnTwo.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                                <StatusRow
                                  key={key}
                                  name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                                  content={(
                                    <PositionValue
                                      position={position}
                                      property={position.hasOwnProperty(key) ? key : null}
                                      attribute={position.hasOwnProperty(key) ? null : key}
                                    />
                                  )}
                                />
                              ))}
                            </MDBTableBody>
                          </MDBTable>
                        </Table>
                      </CardContent>
                    </TabPanel>
                    <TabPanel>
                      {/* sharedShowDetails */}
                      <CardContent className={classes.content}>
                        <Table size="small">
                          <MDBTable scrollY>
                            <MDBTableBody>
                              {positionItemsColumnThree.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                                <StatusRow
                                  key={key}
                                  name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                                  content={(
                                    <PositionValue
                                      position={position}
                                      property={position.hasOwnProperty(key) ? key : null}
                                      attribute={position.hasOwnProperty(key) ? null : key}
                                    />
                                  )}
                                />
                              ))}
                            </MDBTableBody>
                          </MDBTable>
                        </Table>
                      </CardContent>
                    </TabPanel>
                    {(deviceImage) ? (
                      <TabPanel>
                        {/* positionImage */}
                        <CardContent className={classes.content}>
                          <MDBTable scrollY align="middle">
                            <MDBTableBody>
                              <TableCell align="left">
                                <CardMedia
                                  square
                                  style={{ width: '350px', height: '200px' }}
                                  className={classes.img}
                                  image={`/api/media/${device.uniqueId}/${deviceImage}`}
                                />
                              </TableCell>
                            </MDBTableBody>
                          </MDBTable>
                        </CardContent>
                      </TabPanel>
                    ) : null}
                    <TabPanel>
                      {/* deviceCommand */}
                      <CardContent className={classes.content}>
                        <Container maxWidth="xl" className={classes.container}>
                          <Accordion defaultExpanded>
                            {/*
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography variant="subtitle1">
                                {t('sharedRequired')}
                              </Typography>
                            </AccordionSummary>
                            */}
                            <AccordionDetails className={classes.details}>
                              <SelectField
                                value={savedId}
                                emptyValue={limitCommands ? null : 0}
                                emptyTitle={t('sharedNew')}
                                onChange={(e) => setSavedId(e.target.value)}
                                endpoint={`/api/commands/send?deviceId=${deviceId}`}
                                titleGetter={(it) => it.description}
                                label={t('sharedSavedCommand')}
                              />
                              {!limitCommands && !savedId && (
                                <BaseCommandView deviceId={deviceId} item={item} setItem={setItem} />
                              )}
                              <Button
                                type="button"
                                color="primary"
                                variant="outlined"
                                onClick={onClose}
                                onTouchStart={onClose}
                              >
                                {t('sharedCancel')}
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={() => { showToastMessage(); handleSend(); }}
                                disabled={!validate()}
                              >
                                {t('commandSend')}
                              </Button>
                              <ToastContainer />
                            </AccordionDetails>
                          </Accordion>
                        </Container>
                      </CardContent>
                    </TabPanel>
                  </Tabs>
                </Box>
              </Box>
            )}
            <CardActions classes={{ root: classes.actions }} disableSpacing>
              <IconButton
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={!position}
              >
                <PendingIcon />
              </IconButton>
              <IconButton
                onClick={(e) => setAnchorElReport(e.currentTarget)}
                disabled={!position}
              >
                <DescriptionIcon />
              </IconButton>
              <IconButton
                onClick={() => navigate('/replay')}
                disabled={disableActions || !position}
              >
                <ReplayIcon />
              </IconButton>
              {/* Disable icon for command
              <IconButton
                onClick={() => navigate(`/settings/command-send/${deviceId}`)}
                disabled={disableActions || readonly}
              >
                <PublishIcon />
              </IconButton>
              */}
              <IconButton
                onClick={() => navigate(`/settings/device/${deviceId}`)}
                disabled={disableActions || deviceReadonly}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => setRemoving(true)}
                disabled={disableActions || deviceReadonly}
                className={classes.negative}
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Draggable>
      )}
      {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
          <MenuItem onClick={handleGeofence}>{t('sharedCreateGeofence')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}>{t('linkStreetView')}</MenuItem>
        </Menu>
      )}
      {position && (
        <Menu anchorEl={anchorElReport} open={Boolean(anchorElReport)} onClose={() => setAnchorElReport(null)}>
          <MenuItem onClick={() => navigate('/reports/trip')}>
            <PlayCircleFilledIcon>
              <PlayCircleFilledIcon />
            </PlayCircleFilledIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportTrips')}</Typography>
          </MenuItem>
          <MenuItem icon="<PlayCircleFilledIcon />" onClick={() => navigate('/reports/combined')}>
            <StarIcon>
              <StarIcon />
            </StarIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportCombined')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/route')}>
            <TimelineIcon>
              <TimelineIcon />
            </TimelineIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportRoute')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/event')}>
            <NotificationsActiveIcon>
              <NotificationsActiveIcon />
            </NotificationsActiveIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportEvents')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/stop')}>
            <PauseCircleFilledIcon>
              <PauseCircleFilledIcon />
            </PauseCircleFilledIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportStops')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/summary')}>
            <FormatListBulletedIcon>
              <FormatListBulletedIcon />
            </FormatListBulletedIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportSummary')}</Typography>
          </MenuItem>
          <MenuItem onClick={() => navigate('/reports/chart')}>
            <TrendingUpIcon>
              <TrendingUpIcon />
            </TrendingUpIcon>
            &nbsp;&nbsp;
            <Typography color="neutral">{t('reportChart')}</Typography>
          </MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </div>
  );
};

export default StatusCard;
