import { useEffect } from 'react';

const ChatwootWidget = () => {
  useEffect(() => {
    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right', // This can be left or right
      locale: 'es', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
      darkMode: 'auto', // Enable dark mode based on the operating system preference
    };

    // Paste the script from inbox settings except the <script> tag
    (function widgetA(d, t) {
      const BASE_URL = 'https://app.chatwoot.com';
      const g = d.createElement(t);
      const s = d.getElementsByTagName(t)[0];
      g.src = `${BASE_URL}/packs/js/sdk.js`;
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function widgetB() {
        window.chatwootSDK.run({
          websiteToken: 'sc6uZi8LNzqvxLiYF2r48M9i',
          baseUrl: BASE_URL,
        });
      };
    }(document, 'script'));
  }, []);

  return null;
};

export default ChatwootWidget;
